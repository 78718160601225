h1,
h2,
h3 {
  font-family: "Abril Fatface", cursive;
  color: #000;
}
p {
  font-family: "Quicksand", sans-serif;
  color: #878787;
}
a {
  font-family: "Dosis", sans-serif;
  color: #878787;
}
button {
  font-family: "Dosis", sans-serif;
  color: #878787;
}
label {
  font-family: "Dosis", sans-serif;
  color: #878787;
}
.pageHeading {
  font-size: 64px;
}
.message {
  color: green;
}

@media only screen and (max-width: 500px) {
  p.lead {
    font-size: 18px;
  }
  p.lead.pageHeading {
    font-size: 26px;
  }
  h1 {
    font-size: 30px;
  }
}
