/* DASHBOARD */
.dashboardContainer {
  position: relative;
  z-index: 0;
  width: 90%;
  margin-left: 5%;
}
.dashboardLink {
  width: 60px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  height: 60px;
  z-index: 600;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  box-shadow: 3px 3px 1px grey;
}
.dashboardLinkIcon {
  position: relative;
  width: 60px;
  height: 60px;

  margin-top: 30px;
  font-size: 30px;
}
.dashboardLink:hover {
  background-color: grey;
  box-shadow: 4px 4px 2px #fff;
}
.dashboardLink button:hover {
  color: #fff;
}
.dashboardContainer {
  position: relative;
  margin-top: 2em;
}
.dashboardButton {
  margin: 0.5em;
}
.alert {
  color: rgb(119, 179, 95);
}
/* upload */

.uploadFormContainer {
  width: 100%;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
}
.uploadForm {
  width: 80%;
  margin-left: 10%;
  padding-top: 40px;
  border: 1px dashed rgba(0, 0, 0, 0.171);
  margin-top: 20px;
  margin-bottom: 2em;
}
.uploadForm input[type="radio"] {
  width: 20px;
  height: 30px;
}

/* .uploadForm label {
  font-size: 24px;
} */
.formElement {
  width: 90%;
  margin: 1em 5%;
}
.formElement button {
  margin: 0.5em;
}
.imgToUploadThumbContainer {
  width: 100%;
  display: inline-block;
  margin: 2em 0em;
}
.imgToUploadThumb {
  width: 100px;
  margin: 0.2em;
  display: inline-block;
}
.imgToUploadThumb input {
  width: 100px;
}
.imgToUploadThumb img {
  width: 100px;
  height: 100px;
}
.selectedImage {
  border: 2px solid rgb(196, 138, 138);
}
.notSelectedImage {
  border: 2px solid #ccc;
}
.deleteImage {
  margin-top: 2em;
}

.dashboardHeading {
  font-size: 24px;
}
