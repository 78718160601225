.appContainer {
  height: 100%;
}
.leftList {
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
}
.leftList li {
  display: inline-block;
  margin: 0 1em;
  vertical-align: middle;
}
.rightList {
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
}
.rightList li {
  display: inline-block;
  margin: 0 1em;
  vertical-align: middle;
}
.rightList li button {
  margin: 0 !important;
  padding: 0 !important;
}
.rightList li a,
.leftList li a {
  color: #fff;
  /* text-shadow: 1px 2px 3px rgba(0, 0, 0, 1); */
}
.staticList li a {
  color: #000 !important;
  font-size: 20px;
}
.rightList li:hover,
.leftList li:hover {
  text-decoration: underline;
  /* text-shadow: 1px 2px 3px rgba(0, 0, 0, 1); */
}
.rightList li div:hover,
.leftList li div:hover {
  text-decoration: underline;
}
.leftList li a div {
  display: inline;
}
.rightList li a div {
  display: inline;
}
.logo {
  position: relative;
  display: block;
}
.image div {
  width: 100%;
}
.image {
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  display: block;
}
.mobileLink {
  cursor: pointer;
}
.menuContainer {
  align-items: center;
  justify-content: center;
  position: relative;
}
.centeredMenu {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  list-style: none;
}
.centeredMenu li {
  margin: 0.5em 0;
}
.fullMobileMenu {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
}

.mobileMenuLink {
  font-size: 24px;
  color: #000;
}
.mobileMenuLink a {
  font-size: 24px;
  color: #000 !important;
}
.mobileMenuLink button {
  font-size: 24px;
  color: #000 !important;
}
.staticButtonLink {
  font-size: 20px !important;
}
.mobilePortMenu {
  /* background-color: #fff; */
  border: 1px solid black;
  padding: 10px;
  border-radius: 0px;
}
.portfolioDropdown {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-family: "Dosis", sans-serif;
  color: #000;

  cursor: pointer;
}

.exit {
  font-size: 20px;

  cursor: pointer;
  color: #000;
}
.menu {
  font-size: 20px;

  cursor: pointer;
  color: #fff;
}
.mobileNavBar {
  display: flex;
  width: 100%;
  position: fixed;

  top: 0;
  left: 0;
  align-items: center;
  vertical-align: middle;
  margin-top: 3em;
  padding: 0 2em;
}
.relativeMobileNavBar {
  display: flex;
  width: 100%;
  position: relative;
  background-color: rgba(255, 255, 255, 0.425) !important;
  align-items: center;
  vertical-align: middle;
  padding-top: 3em !important;
  padding-bottom: 3em !important;
  margin: 0 !important;
  padding: 0 2em;
}
.staticMobileNavBar {
  display: flex;
  width: 100%;
  position: relative;

  align-items: center;
  vertical-align: middle;
  margin-top: 3em;
  margin-bottom: 3em !important;
  padding: 0 2em;
}
.staticMobileLink {
  margin-right: 1em;
}
.navBar {
  display: flex;
  width: 100%;
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;
  align-items: center;
  vertical-align: middle;
  margin-top: 3em;
}
.staticNavBar {
  z-index: 0 !important;
  background-color: #fff !important;
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  vertical-align: middle;
  padding-top: 2em;
  padding-bottom: 2em;
}
.relativeNavBar {
  z-index: 0 !important;
  background-color: rgba(255, 255, 255, 0.425) !important;
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  vertical-align: middle;
  padding: 2em 0em;
}
.relativeNavBarRow {
  height: 250px;
  width: 100%;
  position: relative;
  top: 0;
  background-color: #fff;
  display: flex;
  z-index: 1;
}
.mobileNavBarRow {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #fff;
  display: flex;
  z-index: 201;
}

.mobileImageContainer {
  height: 58.66px;
}
.navBarRow {
  height: 150px;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #fff;
  display: flex;
}
.staticNavBarRow {
  height: 150px;
  width: 100%;
  position: relative;
  top: 0;
  background-color: #fff;
  display: flex;
  z-index: 1;
}
.navBarRowSticky {
  height: 100px;
  width: 100%;
  position: fixed;
  display: flex;
  z-index: 2;
  top: 0;
  background-color: #fff;
}
.portMenu {
  background-color: rgba(78, 76, 76, 0.4);
  border: none;
  border-radius: 0px;
  position: absolute;
  z-index: 10000 !important;
}
.portMenu li {
  display: block;
}

.active {
  text-decoration: underline;
}
body,
html {
  height: 100%;
}
@media only screen and (max-width: 1600px) {
  .navBarRow {
    height: 115px;
  }
}
@media only screen and (max-width: 1400px) {
  .navBarRow {
    height: 105px;
  }
}
@media only screen and (max-width: 1100px) {
  .rightList li,
  .leftList li {
    margin: 0 0.4em;
  }
  .navBarRow {
    height: 105px;
  }
}
@media only screen and (max-width: 1000px) {
  .rightList li,
  .leftList li {
    margin: 0 0.3em;
    font-size: 12px;
  }

  .navBarRow {
    height: 100px;
  }
}
@media only screen and (max-width: 800px) {
  .rightList li,
  .leftList li {
    margin: 0 0.3em;
    font-size: 12px;
  }

  .navBarRow {
    height: 100px;
  }
}
@media only screen and (max-width: 400px) {
  .mobileNavBar {
    padding: 0 0.5em;
  }
  .navBarRow {
    padding-top: 1em;
  }
}
