.addClient {
  max-width: 500px;
  width: 80%;
  z-index: 300;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 20vh;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1.5em;
  left: 50%;
  margin-left: -20%;
}
.clientsTableContainer {
  margin-top: 2em;
}
.controlButton {
  margin: 0.5em 0.5em;
}
.buttonContainer {
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .addClient {
    left: 10%;
    margin-left: 5px;
  }
}
