.formContainer {
  margin-top: 2em;
  margin-bottom: 2em;
}

.hireCard {
  position: absolute;
  z-index: 1000;
  top: 30vh;
  left: 50%;
  padding: 1em;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1em;
  margin-left: -20%;
}

.pricing {
  padding-top: 100px;
  padding-bottom: 100px;

  width: 100%;
  min-height: 100%;
}

.pricingContainer {
  width: 100%;
  height: 100vh;
}

.overlay {
  width: 100%;
  max-height: 100%;

  padding: 0;

  margin: 0;

  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageHeading {
  align-items: center;
}
.pricing .container {
  background-color: #fff;
}
