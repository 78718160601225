.loadingScreen {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 100;
}
.loadingLogo {
  width: 60%;
  margin-left: 20%;
  margin-top: 30vh;
}
.complete {
  background-color: rgb(112, 223, 112);
}
.confirmation {
  position: absolute;
  z-index: 1000;
  top: 30vh;
  left: 50%;
  padding: 1em;
  box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
  padding: 1em;
  margin-left: -20%;
}
.confirmationControls button {
  margin: 0.3em;
}

@media only screen and (max-width: 600px) {
  .confirmation {
    position: absolute;
    z-index: 1000;
    top: 30vh;
    left: 20%;
    padding: 1em;
    box-shadow: 3px 0px 7px rgba(0, 0, 0, 0.5);
    padding: 1em;
    margin-left: -20%;
  }
}
