.aboutImage {
  max-width: 90%;
  margin-left: 5%;
}
.aboutParagraph {
  width: 80%;
  margin-left: 10%;
}
.landingContainer {
  height: 100%;
}

.landingImage {
  width: auto;
  height: 100vh;
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.landingImage1 {
  width: auto;
  height: 100vh;
  position: relative;
  background-attachment: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.portLinkContainer {
  width: 100%;
  margin: 0 !important;
  position: relative;
}
.portLink {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 150px;
  padding: 10em;
  display: inline-block;
  border: 1px solid white;
  box-sizing: border-box;
  position: relative;
  box-shadow: 2px 2px 10px grey;
}
.portActive p {
  color: #fff !important;
}
.portLink:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.portName {
  width: 100%;
  height: 100%;
  font-size: 30px;
  color: #fff;
  position: absolute;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.616);
  top: 0;
  left: 0;
}
.portName a {
  vertical-align: middle;
}
.portActive {
  background-color: transparent;
}
.portActive a {
  color: #fff;
}
.contactList {
  width: 100%;
}
.green {
  color: green;
}
.features {
  padding: 2em;
}
@media only screen and (max-width: 800px) {
  .aboutParagraph {
    width: 95%;
    margin-left: 2.5%;
  }
  .features {
    padding: 0.5em;
  }
  .portLink {
    padding: 7em;
  }
}
