.portfolio {
  padding-top: 200px;
  width: 100%;
  height: 100%;
}
.relativePortfolio {
  padding-top: 100px;
  width: 100%;
  height: 100%;
}
.portfolioContainer {
  width: 100%;
  height: auto;
  min-height: 100vh;
}
.paddingTop {
  padding-top: -700px;
}
/* .image-gallery-image {
  max-height: 70vh !important;
} */
