.footer {
  background-color: #fff;
  position: relative;
}
.footerContainer {
  border-top: 2px solid black;
}
.footerText {
  color: #878787;
}
.footerText a {
  color: #878787;
}
.margin {
  margin: 0.5em;
  white-space: nowrap;
}
.attribution {
  font-size: 16px;
}
