.pagesImage {
  width: 90%;
}
.activeBackgroundImage img {
  width: 70%;
}
.setImage {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  padding: 3em;
  width: 100%;
  height: 100%;
}
.managementRow {
  margin: 1em 0;
}
.pageManagementControl {
  margin: 1em 0;
}
