.headlineBox {
  background: rgb(80, 80, 80);

  padding: 15px 10px 10px 5px;
}
.headline {
  color: #fff;
}
.downloadZipContainer {
  border: 1px dashed #ccc;
  background: #ccc;
  text-align: center;
  padding-bottom: 7px;
}
.downloadZipContainer button {
  margin: 0 auto;
  width: 100px;
}
.message {
  background-color: rgb(216, 147, 147);
}
